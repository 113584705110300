import React from 'react';
import { Table } from 'antd';
import '../patientprofile.scss';
import { PatientCardWrapper, NoResultsFoundMessage, EVIDENCE_VIEWER_URL_SINGLE_FILE } from '../tile-helper-components';
import { PATIENT_PROJECT_SPECIFIC_TILES } from '..';
import { useNavigate } from 'react-router-dom';

interface Comorbidity {
  name: {
    value: string;
    snippet: string;
  };
}

interface ComorbiditiesCardProps {
  comorbidities: Comorbidity[];
  patientId: number;
  fileId: string;
}

const comorbiditiesColumns = [
  {
    title: 'Comorbidity Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Snippet',
    dataIndex: 'snippet',
    key: 'snippet',
  },
];

export const ComorbiditiesCard: React.FC<ComorbiditiesCardProps> = ({
  comorbidities,
  patientId,
  fileId,
}) => {

  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(
      // `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICAL_CONDITION}`,
      `${EVIDENCE_VIEWER_URL_SINGLE_FILE}&search=${str}&fileId=${fileId}`,
    );
  };

  const comorbiditiesData = comorbidities.map((comorbidity, index) => ({
    key: `comorbidity-${index}`,
    name: comorbidity?.name?.value || 'N/A',
    snippet: comorbidity?.name?.snippet || 'N/A',
  }));

  return (
    <PatientCardWrapper title={PATIENT_PROJECT_SPECIFIC_TILES.comorbidities}>
      <div className="blue flex patient-card tiles-max-height-table">
        {comorbiditiesData.length > 0 ? (
          <Table
            columns={comorbiditiesColumns}
            dataSource={comorbiditiesData}
            pagination={false}
            className="cmn-table"
            rowClassName={'cursor-pointer'}
            onRow={(record) => ({
              onClick: () => handleSearchNavigate(record.name),
            })}
          />
        ) : (
          <NoResultsFoundMessage
            aboveEightyLength={comorbiditiesData.length}
            belowEightyLength={0}
            threshold={100}
          />
        )}
      </div>
    </PatientCardWrapper>
  );
};