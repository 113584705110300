import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import "./ProjectSpecificInsights.scss";
// import {
//   CancerConditionTile,
//   NSCLCDiagnosisTile,
//   PdStatusTile,
//   PembrolizumabTherapyTile,
//   GeneMutationTile,
//   CancerStagingTile,
// } from "./project-specific-tiles";
// import {
//   BloodBasedBiomarkerCard,
//   ConditionsCard,
//   RiskFactorCard,
// } from "./patient-insight-tiles";
import {
  CancerDiagnosisCard,
  TreatmentCard,
  ProcedureCard,
  ComorbiditiesCard,
  RadiologyTestsCard,
  VitalsCard,
  SitesOfMetastasesCard,
  SmokingStatusCard,
} from "./patient-insight-tiles";
import {
  Biomarker,
  Condition,
  PatientFile,
  ProjectSpecific,
  ProjectSpecificTiles,
} from "../../../interfaces";
import { BaseEntity } from "../../../api";
import { getThresholdConfidenceScore } from "../../../utils";
import ProjectSpecificConditionCard from "./patient-insight-tiles/ProjectSpecificConditionCard";
import { summaryService } from "../../../api/summarization";
interface ClinicalDocument {
  [key: string]: string;
}
interface Props {
  projectSpecific: ProjectSpecific[];
  patientId: number;
  threshold_confidence_score: string[];
  biomarkers: Biomarker[];
  riskFactors: BaseEntity[];
  projectSpecificSummary?: any;
  additionalData: any;
  fileList: PatientFile[];
}


export const ProjectSpecificInsightTiles = ({
  projectSpecific,
  patientId,
  threshold_confidence_score,
  biomarkers,
  riskFactors,
  fileList,
  projectSpecificSummary,
  additionalData,
}: Props) => {
  console.log(projectSpecificSummary);
  // const {
  //   cancer_staging,
  //   gene_mutations,
  //   nslc_cancer,
  //   pdl1_status,
  //   pembrolizumab_therapy,
  //   cancer_expression,
  // } = projectSpecific;
  const threshold = getThresholdConfidenceScore(threshold_confidence_score);

  const [question, setQuestion] = useState() as any;

  useEffect(() => {
    const getPrompts = async () => {
      const res = await summaryService.getOneByProjectId(patientId);
    };
    getPrompts();
  }, []);

  return (
    <>
      {/* <h4 className="tile-heading">Project Specific Insights</h4> */}
      <div className="project-specific  m-t">
        <Row gutter={[40, 40]}>
          {projectSpecific && (
            <ProjectSpecificConditionCard project_specifics={projectSpecific} />
          )}
          <Col className="gutter-row" span={12}>
            {additionalData?.cancer_diagnosis && (
              <CancerDiagnosisCard
                cancerDiagnosis={additionalData.cancer_diagnosis}
                patientId={patientId}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>

          <Col className="gutter-row" span={12}>
            {additionalData?.treatment && (
              <TreatmentCard
                treatment={additionalData.treatment}
                patientId={patientId}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>
          
          <Col className="gutter-row" span={12}>
            {additionalData?.procedures && (
              <ProcedureCard
                procedures={additionalData.procedures}
                patientId={patientId}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>

          <Col className="gutter-row" span={12}>
            {additionalData?.comorbidities && (
              <ComorbiditiesCard
                comorbidities={additionalData.comorbidities}
                patientId={patientId}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {additionalData?.radiology_tests && (
              <RadiologyTestsCard
                radiologyTests={additionalData.radiology_tests}
                patientId={patientId}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>

          <Col className="gutter-row" span={12}>
            {additionalData?.vital && (
              <VitalsCard
                vitals={additionalData.vital}
                patientId={patientId}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {additionalData?.sites_of_metastases && (
              <SitesOfMetastasesCard
                metastases={additionalData.sites_of_metastases}
                patientId={patientId}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {additionalData?.smoking_status && (
              <SmokingStatusCard
                smokingStatus={additionalData.smoking_status}
              />
            )}
          </Col>
          {projectSpecificSummary &&
          projectSpecificSummary?.length &&
          Array.isArray(projectSpecificSummary) ? (
            <>
              <div>
                <h4
                  className="insight-card-title"
                  style={{ padding: "0px 20px" }}
                >
                  PROJECT SPECIFIC SUMMARY
                </h4>
                {projectSpecificSummary?.map((item: any, index: number) => (
                  <div key={index} style={{ padding: "20px" }}>
                    <h4>{item.SummarizationTask.title}</h4>
                    <div style={{ whiteSpace: "pre-line" }}>{item.summary}</div>
                  </div>
                ))}
              </div>
              {/* <Col className="gutter-row" span={12}>
                <h4 className="insight-card-title">DEMOGRAPHIC INFORMATION</h4>
                <div>{projectSummary?.["Demographic information"]}</div>
              </Col>
              <Col className="gutter-row" span={12}>
                <h4 className="insight-card-title">
                  CURRENT MEDICAL CONDITIONS
                </h4>
                <div>{projectSummary?.["Current medical conditions"]}</div>
              </Col>
              <Col className="gutter-row" span={12}>
                <h4 className="insight-card-title">
                  RECENT MEDICAL PROCEDURES
                </h4>
                <div>{projectSummary?.["Recent medical procedures"]}</div>
              </Col> */}
            </>
          ) : (
            <Col className="gutter-row" span={12}>
              <h4 className="insight-card-title">PROJECT SPECIFIC SUMMARY</h4>
              <div>{"No Summary found for the provided documentUuid"}</div>
            </Col>
          )}

          {/* <Col className="gutter-row" span={12}>
            {pembrolizumab_therapy && (
              <PembrolizumabTherapyTile
                pembrolizumabTherapy={pembrolizumab_therapy.data}
                patientId={patientId}
                threshold={threshold}
                // threshold={pdl1_status.confidence_score_threshold}
              />
            )}
          </Col> */}

          {/* <Col className="gutter-row" span={9}>
          {biomarkers && (
            <BloodBasedBiomarkerCard
              data={biomarkers}
              patientId={patientId}
              threshold={threshold}
            />
          )}
        </Col> */}
        </Row>
      </div>
    </>
  );
};
