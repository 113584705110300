import React from 'react';
import { Table } from 'antd';
import '../patientprofile.scss';
import {
  PatientCardWrapper,
  NoResultsFoundMessage,
  EVIDENCE_VIEWER_URL_SINGLE_FILE,
} from '../tile-helper-components';
import { PATIENT_PROJECT_SPECIFIC_TILES } from '..';
import { useNavigate } from 'react-router-dom';

interface Treatment {
  medications: {
    name: {
      value: string;
      snippet: string;
    };
    start_date: {
      value: string;
      snippet: string;
    };
  }[];
  surgeries: {
    name: {
      value: string;
      snippet: string;
      score: number;
    };
    date: {
      value: string;
      snippet: string;
    };
  }[];
}

interface TreatmentCardProps {
  treatment: Treatment;
  patientId: number;
  fileId: string;
}

const treatmentColumns = [
  {
    title: 'Treatment Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Snippet',
    dataIndex: 'snippet',
    key: 'snippet',
  },
];

export const TreatmentCard: React.FC<TreatmentCardProps> = ({
  treatment,
  patientId,
  fileId,
}) => {

  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(
      // `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICAL_CONDITION}`,
      `${EVIDENCE_VIEWER_URL_SINGLE_FILE}&search=${str}&fileId=${fileId}`,
    );
  };
  const medications = treatment.medications.map((medication, index) => ({
    key: `medication-${index}`,
    type: 'Medication',
    name: medication?.name?.value || 'N/A',
    date: medication?.start_date?.value || 'N/A',
    snippet: medication.name.snippet || 'N/A',
  }));

  const surgeries = treatment.surgeries.map((surgery, index) => ({
    key: `surgery-${index}`,
    type: 'Surgery',
    name: surgery?.name?.value || 'N/A',
    date: surgery?.date?.value || 'N/A',
    snippet: surgery?.name?.snippet || 'N/A',
  }));



  const dataSource = [...medications, ...surgeries];

  return (
    <PatientCardWrapper title={PATIENT_PROJECT_SPECIFIC_TILES.treatments}>
      <div className="blue flex patient-card tiles-max-height-table">
        {dataSource.length > 0 ? (
          <Table
            columns={treatmentColumns}
            dataSource={dataSource}
            pagination={false}
            className="cmn-table"
            rowClassName={'cursor-pointer'}
            onRow={(record) => ({
              onClick: () => handleSearchNavigate(record.name),
            })}
          />
        ) : (
          <NoResultsFoundMessage
            aboveEightyLength={dataSource.length}
            belowEightyLength={0}
            threshold={100}
          />
        )}
      </div>
    </PatientCardWrapper>
  );
};