import React from 'react';
import { Table } from 'antd';
import '../patientprofile.scss';
import { PatientCardWrapper, NoResultsFoundMessage, EVIDENCE_VIEWER_URL_SINGLE_FILE } from '../tile-helper-components';
import { PATIENT_PROJECT_SPECIFIC_TILES } from '..';
import { useNavigate } from 'react-router-dom';

interface RadiologyTest {
  name: {
    value: string;
    snippet: string;
  };
  date: {
    value: string;
    snippet: string;
  };
}

interface RadiologyTestsCardProps {
  radiologyTests: RadiologyTest[];
  patientId: number;
  fileId: string;
}

const radiologyTestsColumns = [
  {
    title: 'Test Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Snippet',
    dataIndex: 'snippet',
    key: 'snippet',
  },
];

export const RadiologyTestsCard: React.FC<RadiologyTestsCardProps> = ({
  radiologyTests,
  patientId,
  fileId,
}) => {
  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(
      // `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICAL_CONDITION}`,
      `${EVIDENCE_VIEWER_URL_SINGLE_FILE}&search=${str}&fileId=${fileId}`,
    );
  };
  const radiologyTestsData = radiologyTests.map((test, index) => ({
    key: `radiology-test-${index}`,
    name: test?.name?.value || 'N/A',
    date: test?.date?.value || 'N/A',
    snippet: test?.name?.snippet || 'N/A',
  }));

  return (
    <PatientCardWrapper title={PATIENT_PROJECT_SPECIFIC_TILES.radiology}>
      <div className="blue flex patient-card tiles-max-height-table">
        {radiologyTestsData.length > 0 ? (
          <Table
            columns={radiologyTestsColumns}
            dataSource={radiologyTestsData}
            pagination={false}
            className="cmn-table"
            rowClassName={'cursor-pointer'}
            onRow={(record) => ({
              onClick: () => handleSearchNavigate(record.name),
            })}
          />
        ) : (
          <NoResultsFoundMessage
            aboveEightyLength={radiologyTestsData.length}
            belowEightyLength={0}
            threshold={100}
          />
        )}
      </div>
    </PatientCardWrapper>
  );
};