import React from "react";
import { Row, Col } from "antd";
import {
  ConditionsCard,
  CurrentInfectiousDiseaseCard,
  ImmunizationHistoryCard,
  PatientMedicationHistory,
  SocialHistoryCard,
  PastTreatmentProceduresCard,
} from "./patient-insight-tiles";
import { IPatient } from "../../../interfaces";
import { getThresholdConfidenceScore } from "../../../utils";

interface Props {
  patientInfo: IPatient;
}

export const PatientInsightTiles = ({ patientInfo }: Props) => {
  const {
    patientId,
    conditions,
    // demographics,
    // family_medical_history,
    fileList,
    immunization_history,
    infectious_diseases,
    // lifestyle,
    medication_history,
    threshold_confidence_score,
    // biomarkers,
    // risk_factor,
    past_history,
    social_history,
    additionalData, // Add additionalData to destructuring
  } = patientInfo;

  const threshold = getThresholdConfidenceScore(threshold_confidence_score);

  return (
    <>
      <div className="project-specific m-t">
        <Row gutter={[40, 40]}>
          <Col className="gutter-row" span={12}>
            {conditions && (
              <ConditionsCard conditions={conditions} patientId={patientId} threshold={threshold} fileId={fileList[0]?.UUID} />
            )}
          </Col>
          <Col className="infectious gutter-row" span={12}>
            {infectious_diseases && (
              <CurrentInfectiousDiseaseCard
                infecitous_disease={infectious_diseases}
                patientId={patientId}
                threshold={threshold}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            {medication_history && (
              <PatientMedicationHistory
                patientId={patientId}
                medication_history={medication_history}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {immunization_history && (
              <ImmunizationHistoryCard data={immunization_history} patientId={patientId} fileId={fileList[0]?.UUID} />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {past_history && (
              <PastTreatmentProceduresCard
                pastTreatmentProcedures={past_history}
                patientId={patientId}
                threshold={threshold}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {social_history && (
              <SocialHistoryCard
                socialHistory={social_history}
                patientId={patientId}
                threshold={threshold}
                fileId={fileList[0]?.UUID}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};