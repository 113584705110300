import React from 'react';
import { Table } from 'antd';
import '../patientprofile.scss';
import { PatientCardWrapper, NoResultsFoundMessage, EVIDENCE_VIEWER_URL_SINGLE_FILE } from '../tile-helper-components';
import { PATIENT_PROJECT_SPECIFIC_TILES } from '..';
import { useNavigate } from 'react-router-dom';

interface Procedure {
  name: {
    value: string;
    score: number;
    snippet: string;
  };
  date: {
    value: string;
    score: number;
    snippet: string;
  };
}

interface ProcedureCardProps {
  procedures: Procedure[];
  patientId: number;
  fileId: string;
}

const procedureColumns = [
  {
    title: 'Procedure Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Snippet',
    dataIndex: 'snippet',
    key: 'snippet',
  },
];

export const ProcedureCard: React.FC<ProcedureCardProps> = ({ procedures, patientId, fileId }) => {

  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(
      // `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICAL_CONDITION}`,
      `${EVIDENCE_VIEWER_URL_SINGLE_FILE}&search=${str}&fileId=${fileId}`,
    );
  };

  const procedureData = procedures.map((procedure, index) => ({
    key: `procedure-${index}`,
    name: procedure?.name?.value || 'N/A',
    date: procedure?.date?.value || 'N/A',
    snippet: procedure?.name?.snippet || procedure.date.snippet || 'N/A',
  }));

  return (
    <PatientCardWrapper title={PATIENT_PROJECT_SPECIFIC_TILES.procedures}>
      <div className="blue flex patient-card tiles-max-height-table">
        {procedureData.length > 0 ? (
          <Table
            columns={procedureColumns}
            dataSource={procedureData}
            pagination={false}
            className="cmn-table"
            rowClassName={'cursor-pointer'}
            onRow={(record) => ({
              onClick: () => handleSearchNavigate(record.name),
            })}
          />
        ) : (
          <NoResultsFoundMessage
            aboveEightyLength={procedureData.length}
            belowEightyLength={0}
            threshold={100}
          />
        )}
      </div>
    </PatientCardWrapper>
  );
};