import React from 'react';
import { Table } from 'antd';
import '../patientprofile.scss';
import { PatientCardWrapper, NoResultsFoundMessage, EVIDENCE_VIEWER_URL_SINGLE_FILE } from '../tile-helper-components';
import { PATIENT_PROJECT_SPECIFIC_TILES } from '..';
import { useNavigate } from 'react-router-dom';

interface Site {
  value: string;
  snippet: string;
}

interface Metastasis {
  site: Site;
}

interface SitesOfMetastasesCardProps {
  metastases: Metastasis[];
  patientId: number;
  fileId: string;
}

const metastasesColumns = [
  {
    title: 'Metastasis Site',
    dataIndex: 'site',
    key: 'site',
  },
  {
    title: 'Snippet',
    dataIndex: 'snippet',
    key: 'snippet',
  },
];

export const SitesOfMetastasesCard: React.FC<SitesOfMetastasesCardProps> = ({ metastases, patientId, fileId }) => {

  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(
      // `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICAL_CONDITION}`,
      `${EVIDENCE_VIEWER_URL_SINGLE_FILE}&search=${str}&fileId=${fileId}`,
    );
  };

  const metastasisEntries = metastases.map((metastasis, index) => ({
    key: `metastasis-${index}`,
    site: metastasis?.site?.value || 'N/A',
    snippet: metastasis?.site?.snippet || 'N/A',
  }));

  return (
    <PatientCardWrapper title={PATIENT_PROJECT_SPECIFIC_TILES.sitesOfMetastasases}>
      <div className="blue flex patient-card tiles-max-height-table">
        {metastasisEntries.length > 0 ? (
          <Table
            columns={metastasesColumns}
            dataSource={metastasisEntries}
            pagination={false}
            className="cmn-table"
            rowClassName={'cursor-pointer'}
            onRow={(record) => ({
              onClick: () => handleSearchNavigate(record.site),
            })}
          />
        ) : (
          <NoResultsFoundMessage
            aboveEightyLength={metastasisEntries.length}
            belowEightyLength={0}
            threshold={100}
          />
        )}
      </div>
    </PatientCardWrapper>
  );
};