import React from 'react';
import { Card } from 'antd';
import '../patientprofile.scss';
import { PatientCardWrapper } from '../tile-helper-components';
import { PATIENT_PROJECT_SPECIFIC_TILES } from '..';

interface SmokingStatus {
  value: string;
  snippet: string;
}

interface SmokingStatusCardProps {
  smokingStatus: SmokingStatus;
}

export const SmokingStatusCard: React.FC<SmokingStatusCardProps> = ({ smokingStatus }) => {
  return (
    <PatientCardWrapper title={PATIENT_PROJECT_SPECIFIC_TILES.smokingStatus}>
      <div className="blue flex patient-card tiles-max-height">
        <Card bordered={false} className="smoking-status-card">
          <p><strong>Status:</strong> {smokingStatus.value || 'N/A'}</p>
          <p><strong>Snippet:</strong> {smokingStatus.snippet || 'N/A'}</p>
        </Card>
      </div>
    </PatientCardWrapper>
  );
};