import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Table } from 'antd';
import '../patientprofile.scss';
import { PATIENT_PROJECT_SPECIFIC_TILES } from '..';

import {
  PatientCardWrapper,
  NoResultsFoundMessage,
  EVIDENCE_VIEWER_URL_SINGLE_FILE,
} from '../tile-helper-components';

interface CancerDiagnosis {
  cancer_type: {
    value: string;
    snippet: string;
  };
  date_of_diagnosis: {
    value: string;
    snippet: string;
  };
  cancer_stage: {
    value: string;
    snippet: string;
  };
}

interface CancerDiagnosisCardProps {
  cancerDiagnosis: CancerDiagnosis;
  patientId: number;
  fileId: string;
}

const columns = [
  {
    title: 'Cancer Detail',
    dataIndex: 'detail',
    key: 'detail',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'Snippet',
    dataIndex: 'snippet',
    key: 'snippet',
  },
];

const isNonEmpty = (data: any): boolean => {
  if (Array.isArray(data)) return data.length > 0;
  if (typeof data === "object" && data !== null) return Object.keys(data).length > 0;
  return !!data;
};


export const CancerDiagnosisCard: React.FC<CancerDiagnosisCardProps> = ({
  cancerDiagnosis,
  patientId,
  fileId,
}) => {

  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(
      // `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICAL_CONDITION}`,
      `${EVIDENCE_VIEWER_URL_SINGLE_FILE}&search=${str}&fileId=${fileId}`,
    );
  };

  const dataSource = isNonEmpty(cancerDiagnosis) ? [
    {
      key: 'cancer_type',
      detail: 'Cancer Type',
      value: cancerDiagnosis.cancer_type.value || 'N/A',
      snippet: cancerDiagnosis.cancer_type.snippet || 'N/A',
    },
    {
      key: 'date_of_diagnosis',
      detail: 'Date of Diagnosis',
      value: cancerDiagnosis.date_of_diagnosis.value || 'N/A',
      snippet: cancerDiagnosis.date_of_diagnosis.snippet || 'N/A',
    },
    {
      key: 'cancer_stage',
      detail: 'Cancer Stage',
      value: cancerDiagnosis.cancer_stage.value || 'N/A',
      snippet: cancerDiagnosis.cancer_stage.snippet || 'N/A',
    },
  ] : [];

  return (
    <>
      <PatientCardWrapper title={PATIENT_PROJECT_SPECIFIC_TILES.cancerDiagnosis}>
        <div className="blue flex patient-card tiles-max-height-table">
          {dataSource.length > 0 ? (
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              className="cmn-table"
              rowClassName={'cursor-pointer'}
              onRow={(record) => ({
                onClick: () => handleSearchNavigate(record.value),
              })}
            />
          ) : (
            <NoResultsFoundMessage
              aboveEightyLength={dataSource.length}
              belowEightyLength={0}
              threshold={100}
            />
          )}
        </div>
      </PatientCardWrapper>
    </>
  );
};