import React, { useMemo } from 'react';
import { PatientProjects } from '../../../api';
import { PatientInsightsQuickLinks, ProjectInsightsQuickLinks } from './pateint-insight-header';
import { PatientInsightTiles } from './PatientInsightTiles';
import { ProjectSpecificInsightTiles } from './ProjectSpecificInsightTiles';
import { IPatient, ProjectSpecific, ProjectSpecificTiles } from '../../../interfaces';

interface ClinicalInsightsProps {
  patientProjects: PatientProjects[];
  patientInfo: IPatient;
}

interface ProjectInsightsProps {
  projectSpecific: ProjectSpecific[];
  projectSpecificSummary: any;
  patientInfo: IPatient;
}

export const ClinicalInsights = ({ patientProjects, patientInfo }: ClinicalInsightsProps) => {
  const projectSpecific = useMemo(() => {
    return patientInfo.project_specific
      ? patientInfo.project_specific
      : ({} as ProjectSpecificTiles);
  }, [patientInfo.project_specific]);

  return (
    <div>
      <PatientInsightsQuickLinks
        displayProjectSpecificTiles={false}
        // displayProjectSpecificTiles={patientProjects.length > 0}
      />
      <div className="clinical-insight">
        <h4 className="title-bd-bt">
          Clinical factors derived from Unstructured data
        </h4>
        {/* {patientProjects.length > 0 && (
          <ProjectSpecificInsightTiles
            projectSpecific={projectSpecific}
            patientId={patientInfo.patientId}
            threshold_confidence_score={patientInfo.threshold_confidence_score}
            biomarkers={patientInfo.biomarkers}
            riskFactors={patientInfo.risk_factor}
          />
        )} */}
        <PatientInsightTiles patientInfo={patientInfo} />
      </div>
    </div>
  );
};

export const ProjectInsights = ({ projectSpecific, projectSpecificSummary, patientInfo }: ProjectInsightsProps) => {

  return (
    <div>
      <ProjectInsightsQuickLinks
        // displayProjectSpecificTiles={patientProjects.length > 0}
      />
      <div className="clinical-insight">
        <h4 className="title-bd-bt">
          Clinical factors derived from Unstructured data
        </h4>
        {(
          <ProjectSpecificInsightTiles
            projectSpecific={projectSpecific}
            patientId={patientInfo.patientId}
            threshold_confidence_score={patientInfo.threshold_confidence_score}
            biomarkers={patientInfo.biomarkers}
            riskFactors={patientInfo.risk_factor}
            projectSpecificSummary={projectSpecificSummary}
            additionalData={patientInfo.additionalData}
            fileList={patientInfo.fileList}
        />
        )}
      </div>
    </div>
  );
};
