import React from 'react';
import { Table } from 'antd';
import '../patientprofile.scss';
import { PatientCardWrapper, NoResultsFoundMessage, EVIDENCE_VIEWER_URL_SINGLE_FILE } from '../tile-helper-components';
import { PATIENT_PROJECT_SPECIFIC_TILES } from '..';
import { useNavigate } from 'react-router-dom';


interface VitalMeasurement {
  value: number | string;
  unit?: string;
  snippet: string;
}

interface Vital {
  date: {
    value: string;
    snippet: string;
  };
  [key: string]: VitalMeasurement | { value: string; snippet: string }; // Allow dynamic keys
}

interface VitalsCardProps {
  vitals: Vital;
  patientId: number;
  fileId: string;
}

const vitalsColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Measurement',
    dataIndex: 'measurement',
    key: 'measurement',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'Snippet',
    dataIndex: 'snippet',
    key: 'snippet',
  },
];

export const VitalsCard: React.FC<VitalsCardProps> = ({ vitals, patientId, fileId }) => {
  
  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(
      // `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICAL_CONDITION}`,
      `${EVIDENCE_VIEWER_URL_SINGLE_FILE}&search=${str}&fileId=${fileId}`,
    );
  };
  
  const vitalEntries = Object.keys(vitals)
    .filter((key) => key !== 'date') // Exclude date from being treated as a measurement
    .map((key) => {
      const vital = vitals[key] as VitalMeasurement;
      return {
        key,
        measurement: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '), // Format the key
        value: vital ? `${vital.value} ${vital.unit || ''}` : 'N/A',
        snippet: vital.snippet || 'N/A',
        date: vitals.date?.value || 'N/A',
      };
    });

  return (
    <PatientCardWrapper title={PATIENT_PROJECT_SPECIFIC_TILES.vitals}>
      <div className="blue flex patient-card tiles-max-height-table">
        {vitalEntries.length > 0 ? (
          <Table
            columns={vitalsColumns}
            dataSource={vitalEntries}
            pagination={false}
            className="cmn-table"
            rowClassName={'cursor-pointer'}
            onRow={(record) => {
              return {
                onClick: () => handleSearchNavigate(record.measurement),
              };
            }}
          />
        ) : (
          <NoResultsFoundMessage
            aboveEightyLength={vitalEntries.length}
            belowEightyLength={0}
            threshold={100}
          />
        )}
      </div>
    </PatientCardWrapper>
  );
};