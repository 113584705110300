export * from "./PatientInsightActionItems";
export * from "./PatientInsightTiles";
export * from "./ProjectSpecificInsightTiles";
export * from "./PatientInsightsTabs";
export * from "./Summary";
export * from "./AskAQuestion";
export * from "./ClinicalInsights";
export * from "./HedisMeasureInsights";
export * from "./HedisCareGaps";

export const PATIENT_PROJECT_SPECIFIC_TILES = {
  // nsclc: "Non-Small Cell Lung Cancer",
  // cancerCondition: "Cancer Condition",
  // geneMutations: "Gene Mutations",
  // pdl1Status: "PD-L1 Status",
  // // pembrolizumabTherapy: "Pembrolizumab Therapy",
  // cancerStaging: "Cancer Staging",
  // riskFactors: "Risk Factors",
  cancerDiagnosis: "Cancer Diagnosis",
  treatments: "Treatments",
  procedures: "Procedures",
  comorbidities: "Comorbidities",
  radiology: "Radiology Tests",
  vitals: "Vitals",
  sitesOfMetastasases: "Sites of Metastases",
  smokingStatus: "Smoking Status",
};

export const PATIENT_INSIGHT_TILES = {
  // demographics: "Demographics",
  conditions: "Conditions",
  infectiousDisease: "Infectious Diseases",
  // dataSource: "Source Charts/Data Sources",
  medicationHistory: "Medication/RX History",
  immunizationHistory: "Immunization History",
  pastTreatmentProcedure: "Past Treatment Procedures",
  socialHistory: "Social History"
};

export const HEDIS_MEASURE = "HEDIS Measure: ";

export const HEDIS_MEASURE_INSIGHTS_TILES = {
  HEDIS_BCS: `${HEDIS_MEASURE}BCS`,
  HEDIS_COL: `${HEDIS_MEASURE}COL`,
  HEDIS_CCS: `${HEDIS_MEASURE}CCS`,
  HEDIS_CDC: `${HEDIS_MEASURE}CDC`,
};

export interface IQuestAns {
  question: string;
  answer: string;
}
